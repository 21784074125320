<template>

</template>
<script>
export default {
    created(){
        // 来源监测-二维码引流注册
        this.$store.commit('app/SET_IS_TWITTER',2)
        this.$router.push({
            path:'/light'
        })
    }
}
</script>